import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop';
import { DragDropData } from '../../models/drag-drop-data';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbDate, NgbDateStruct, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Components, tempFormData, DECIMAL_POINT_DATA, MULTI_ROW_DESIGN, MIN_DATE_CONSTANT, MAX_DATE_CONSTANT, TABLE_COMPONENT_TYPES, componentTypeMap } from './../../constant/constant';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { COLUMN_REQUIRED_MSG, DRAG_DROP_MESSAGE, FIELD_ANNOTATEDNAME_ERROR_MSG, FIELD_HEADING_ERROR_MSG, FIELD_LABEL_ERROR_MSG, FIELD_LINK_ERROR_MSG, FIELD_TAG_ERROR_MSG, FORM_ANNOTATEDNAME_ERROR_MSG, IMAGE_NOT_FOUND_MSG, IMAGE_SIZE_MSG, LINK_ERROR_MSG, MINIMUM_OPTION_ERROR_MSG, MOVE_QUESTION, OPTION_CODE_ERROR_MSG, OPTION_NAME_ERROR_MSG, OPTION_VALUE_ERROR_MSG, TIME_ERROR_MESSAGE, TIME_SELECTION_MESSAGE } from 'src/app/constant/responseMessage';
import { UnitService } from 'src/app/services/unit.service';
import { DateFormatService } from 'src/app/services/date-format.service';
import * as loadashJson from 'lodash';
import { FieldService } from 'src/app/services/field.service';
import { cloneArray } from 'src/app/constant/globalFunction';
import { Role } from 'src/app/models/Role';

@Component({
  selector: 'app-form-popup',
  templateUrl: './form-popup.component.html',
  styleUrls: ['./form-popup.component.css']
})
export class FormPopupComponent implements OnInit {
  @Input() showComponentName?: any;
  @Input() editFieldId: any;

  @Input() visitDateFieldName:string = "";
  @Output() passVisitDateFieldName: EventEmitter<string> = new EventEmitter();

  @Input() isCopyField: boolean = false;
  editFormId:any
  popupForm?: FormGroup;
  isdefault: boolean = false;
  tempForm:any[] = tempFormData;
  alignment = [
    "right", "center", "left"
  ];
  head: any[] = [
    "Large",
    "Medium",
    "Small",
  ];
  radioAlign: any[] = [
    "Horizontal",
    "Vertical"
  ];
  i:any = 0;
  dateFormat: any
  // componentsElement = new Array();
  componentsElement: any = {};
  isEdit: boolean = false;
  hour:string = ''
  minute:string = ''
  second:string = ''
  midDay:string = ''
  indexData: any;
  editIndexData:any;
  copyIndexData:any

  loading:boolean = false

  studyFormat : string = '';

  emptyJson:any = {}

  //Multi-Row Component
  columnComponentsElement: any[] = [];
  columnComponentsElementArray: any[] = [];
  selectType:any[] = [
    {type:"TextView"},
    //{type:"Email"},
    {type:"Number"},
    {type:"CheckBox"},
    {type:"RadioButton"},
    {type:"SelectionBox"},
    {type:"Date"},
    {type:"Time"},
    {type:"TextArea"},
    {type:"Label"},
  ]
  mainType:any[] = []
  dataDone: DragDropData[] = [];
  temp:any;
  columnData:any[] = []
  heading:any[] = [];
  componentName:any[] = []
  placeholderExist:any[] = [1,3,4]

  unitData:any

  //For Decimal Point Data
  decimalData:any = DECIMAL_POINT_DATA

  //For multi row view
  multiRowDesign:any = MULTI_ROW_DESIGN

  //For Table Component Design
  tableType:any[] = TABLE_COMPONENT_TYPES
  fieldType:any[] = cloneArray(TABLE_COMPONENT_TYPES);
  
  //For Alphabet & Alphanumeric
  radioButtonData:any[] = [
    {name:"Alphabet",value: 0},
    {name:"Alphanumeric",value:1}
  ]

  //For Time
  hours12: any[] = []
  hours24: any[] = []
  minutes: any[] = []
  seconds: any[] = []
  midday: any[] = ["AM", "PM"]

  //For Date
  dateData?: any[] = []
  monthData?: any[] = [1,2,3,4,5,6,7,8,9,10,11,12]
  yearData?: any[] = []

  // Min/Max Date
  minConstant:any = MIN_DATE_CONSTANT
  maxConstant:any = MAX_DATE_CONSTANT
  model?: NgbDateStruct;

  //OldData
  oldCellData!: FormArray;
  readOnly:any;
  roles:any;
  constructor(private fieldAPI:FieldService,private unitAPI:UnitService,
    private dateFormatAPI:DateFormatService,private modalService: NgbModal,
    private ngbActiveModal: NgbActiveModal,
    private toastr:ToastrService,private config:NgbDatepickerConfig) {
      config.minDate = MIN_DATE_CONSTANT
      config.maxDate = MAX_DATE_CONSTANT
  }

  ngOnInit(): void {
    this.readOnly = Role.ReadOnly;
    this.roles = sessionStorage.getItem('role');
    this.fieldType.splice(3,2);
    this.getUnits()
    this.studyFormat = sessionStorage.getItem('studyDateFormat')??'';
    this.studyFormat = this.studyFormat.substring(0,this.studyFormat.indexOf(' ')).toUpperCase();
    this.getDateFormats()
    this.editIndexData = localStorage.getItem("element")
    this.copyIndexData = localStorage.getItem("copyElement")
    this.editFormId =  localStorage.getItem("editFormId") !== 'null' ? localStorage.getItem("editFormId") : null
    this.indexData = this.editIndexData ? parseInt(this.editIndexData) : null
    if (this.showComponentName != undefined) {
      this.initForm(this.showComponentName);
      this.setEditDataValues();
    }
    // if(this.indexData != null){
    //   this.isEdit = true
    // }else{
    //   this.isEdit = false
    // }

    if(this.editFieldId != null ||this.indexData != null){
      this.isEdit = true
    }else{
      this.isEdit = false
    }

    let link = this.popupForm?.get('link')?.value;

    
    if (!/^https?:\/\//i.test(link)) {
      link = 'https://' + link;
      this.popupForm?.get('link')?.setValue(link)
    }
  }
  ngDoCheck() {
    this.tempForm = tempFormData;
  }

  getUnits(){
    this.unitAPI.getAllUnits().subscribe(res=>{
      this.unitData = res.responseObject
    })
  }

  getDateFormats(){
    this.dateFormatAPI.getDateFormats().subscribe(res=>{
      this.dateFormat = res.responseObject
    })
  }

  addControlsToForm(compName: any, list: any[]) {
    list.forEach(controlName => {//formArray (for Options)
      if (controlName == "options") {
        this.popupForm?.addControl(controlName, new FormArray([this.createOption()]));
      }
      else if(controlName == "columnData"){
        this.popupForm?.addControl(controlName,new FormArray([]));
      }
      else if(controlName == "cellInfo"){
        this.popupForm?.addControl(controlName,new FormArray([]))
        // this.popupForm?.addControl(controlName,new FormGroup({
        //   label:new FormControl(),
        //   row:new FormControl(),
        //   column:new FormControl(),
        //   data:new FormArray([])
        // }))
      }
      else if (controlName == "formatting") {
        if (compName == "RadioButton") {
          this.popupForm?.addControl(controlName, new FormGroup({
            radioAlignment: new FormControl('Vertical')
          }));
        }else if (compName == "SubHeading") {
          this.popupForm?.addControl(controlName, new FormGroup({
            bold: new FormControl(''),
            italic: new FormControl(''),
            underline: new FormControl(''),
            alignment: new FormControl(''),
            color: new FormControl(''),
          }));
        }
        else if (compName == "Heading") {
          this.popupForm?.addControl(controlName, new FormGroup({
            headingSize: new FormControl('Large'), //added Default data if user donsen't select any data
            divider: new FormControl(''),
          }));
        }
        else if (compName == "Date") {
          this.popupForm?.addControl(controlName, new FormGroup({
            dateFormat: new FormControl(this.studyFormat),
          }));
        }
        else if (compName == "Time") {
          this.popupForm?.addControl(controlName, new FormGroup({
            hour: new FormControl(true),
            minute: new FormControl(true),
            second: new FormControl(true),
            midDay: new FormControl(true),
            hourFormat: new FormControl('12 hrs')
          }));
        }else if (compName == "Image") {
          this.popupForm?.addControl(controlName, new FormGroup({
            isdefault: new FormControl(true),
            alignment: new FormControl('')
          }));
        }else if(compName == "Table"){
          this.popupForm?.addControl(controlName, new FormGroup({
            row: new FormControl(),
            column: new FormControl()
          }));
        }else if(compName == "Date & Time"){
          this.popupForm?.addControl(controlName, new FormGroup({
            dateFormat: new FormControl(this.studyFormat),
            hour: new FormControl(true),
            minute: new FormControl(true),
            second: new FormControl(true),
            midDay: new FormControl(true),
            hourFormat: new FormControl('12 hrs')
          }));
        }
        
      }
      else {
        // formControls
        if (controlName === 'link') {
          this.popupForm?.addControl(controlName, new FormControl('', [Validators.required, Validators.pattern('')]));//create new FormControl
        }else if (controlName === 'multiRowView') {
          this.popupForm?.addControl(controlName, new FormControl(2, [Validators.required, Validators.pattern('')]));//create new FormControl
        } else if (controlName === 'isVisitDate') {
          this.popupForm?.addControl(controlName, new FormControl(false));
        }
        this.popupForm?.addControl(controlName, new FormControl(''));//create new FormControl
      }
      this.componentsElement[controlName] = true;//for control html
    });
  }

  validateData(data:any){
    let emptyJson:any = {}
    if(this.popupForm?.value.columnData == undefined || this.popupForm?.value.columnData == ''){
      if (this.popupForm?.value.label != undefined && this.popupForm?.value.label == '') {
        this.toastr.warning(FIELD_LABEL_ERROR_MSG);
        return false
      }
      else if (this.popupForm?.value.annotatedName != undefined && (this.popupForm?.value.annotatedName == null || this.popupForm?.value.annotatedName == '')) {
        this.toastr.warning(FIELD_ANNOTATEDNAME_ERROR_MSG);
        return false
      }
      // else if (this.popupForm?.value.tag != undefined && (this.popupForm?.value.tag == null || this.popupForm?.value.tag == '')) {
      //   this.toastr.warning(FIELD_TAG_ERROR_MSG);
      //   return false
      // }
      else if (this.popupForm?.value.link != undefined && this.popupForm?.value.link == '') {
        this.toastr.warning(FIELD_LINK_ERROR_MSG);
        return false

      }else if(this.popupForm?.value.formatting != undefined){
        if(this.popupForm?.value.formatting.hourFormat == ''){
          this.toastr.warning(TIME_ERROR_MESSAGE);
          return false;
  
        }else if(this.popupForm?.value.formatting.hourFormat == '12 hrs' || this.popupForm?.value.formatting.hourFormat == '24 hrs'){
          if(this.popupForm?.value.formatting.hour == false && this.popupForm?.value.formatting.minute == false && this.popupForm?.value.formatting.second == false && this.popupForm?.value.formatting.midDay == false){
            this.toastr.warning(TIME_SELECTION_MESSAGE);
            return false;
          }
  
        }
      }else if (this.popupForm?.value.options != undefined) {
        for (let i = 0; i < this.popupForm?.value.options.length; i++) {
          if (this.popupForm?.value.options[i].name == null || this.popupForm?.value.options[i].name == "") {
            this.toastr.warning(OPTION_NAME_ERROR_MSG);
            return false;
          } else if (this.popupForm?.value.options[i].value == null || this.popupForm?.value.options[i].value == "") {
            this.toastr.warning(OPTION_VALUE_ERROR_MSG);
            return false;
          } else if (this.popupForm?.value.options[i].code == null || this.popupForm?.value.options[i].code < 0) {
            this.toastr.warning(OPTION_CODE_ERROR_MSG);
            return false;
          }
        }
      }
      
      if ((this.popupForm?.value.minDate != undefined && this.popupForm?.value.minDate == '') || (data.minDate != undefined && data.minDate == '')) {
        this.popupForm?.controls['minDate'].setValue(emptyJson)
        data.minDate = emptyJson
      }
      if ((this.popupForm?.value.maxDate != undefined && this.popupForm?.value.maxDate == '') || (data.maxDate != undefined && data.maxDate == '')) {
        this.popupForm?.controls['maxDate'].setValue(emptyJson)
        data.maxDate = emptyJson
      }
      if(!this.linkValidationCheck()){
        this.toastr.warning(LINK_ERROR_MSG);
        return false
      }
    }else{
      if (this.popupForm?.value.label != undefined && this.popupForm?.value.label == '') {
        this.toastr.warning(FIELD_HEADING_ERROR_MSG);
        return false
      }else if(this.popupForm?.value.multiRowView == undefined || this.popupForm?.value.multiRowView == ''){
        this.toastr.warning("Please select Multi Row Design")
        return
      }
      for (let col = 0; col < this.popupForm?.value.columnData.length; col++) {
        if(this.popupForm?.value.columnData[col].colHeading == undefined || this.popupForm?.value.columnData[col].colHeading == ''){
          this.toastr.warning("Column heading is required")
          return false

        }else if(this.popupForm?.value.columnData[col].type == undefined || this.popupForm?.value.columnData[col].type == ''){
          this.toastr.warning("Column type is required")
          return false

        }else if(this.popupForm?.value.columnData[col].dataTypeId != componentTypeMap[14].id && (this.popupForm?.value.columnData[col].annotatedName == undefined || this.popupForm?.value.columnData[col].annotatedName == '')){
          this.toastr.warning("Column annotated name is required")
          return false

        }else if(this.popupForm?.value.columnData[col].formatting != undefined){
          if(this.popupForm?.value.columnData[col].formatting.hourFormat == undefined && this.popupForm?.value.columnData[col].formatting.hourFormat == ''){
            this.toastr.warning(TIME_ERROR_MESSAGE);
            return false;
            
          }else if(this.popupForm?.value.columnData[col].formatting.hourFormat != undefined && this.popupForm?.value.columnData[col].formatting.hourFormat == '12 hrs' || this.popupForm?.value.columnData[col].formatting.hourFormat == '24 hrs'){
            if(this.popupForm?.value.columnData[col].formatting.hour == false && this.popupForm?.value.columnData[col].formatting.minute == false && this.popupForm?.value.columnData[col].formatting.second == false && this.popupForm?.value.columnData[col].formatting.midDay == false){
              this.toastr.warning(TIME_SELECTION_MESSAGE);
              return false;
            }
    
          }

        }else if (this.popupForm?.value.columnData[col].options != undefined) {
          for (let i = 0; i < this.popupForm?.value.columnData[col].options.length; i++) {
            if (this.popupForm?.value.columnData[col].options[i].name == null || this.popupForm?.value.columnData[col].options[i].name == "") {
              this.toastr.warning(OPTION_NAME_ERROR_MSG);
              return false;
            } else if (this.popupForm?.value.columnData[col].options[i].value == null || this.popupForm?.value.columnData[col].options[i].value == "") {
              this.toastr.warning(OPTION_VALUE_ERROR_MSG);
              return false;
            } else if (this.popupForm?.value.columnData[col].options[i].code == null || this.popupForm?.value.columnData[col].options[i].code < 0) {
              this.toastr.warning(OPTION_CODE_ERROR_MSG);
              return false;
            }
          }
        }
      }
    }
    
    return true
  }

  checkOperatorDataType(data:any){
    if ((this.editFormId != 'null' && typeof(this.editFormId) != 'string')) {
      this.addNewField();
    }else{
      if(!isNaN(this.indexData) && this.indexData !== null){
        this.tempForm.splice(this.indexData,1,data)
      }else{
        tempFormData.push(data);
      }
      this.popupForm?.reset();
      localStorage.removeItem("element")
      localStorage.removeItem("copyElement")
      this.modalService.dismissAll("Dismiss");
    }
  }

  pushFormData(data: any) {
    if(data.dataTypeId == componentTypeMap[16].id){
      this.validateMultiRowOptions(data)
    }
    if (this.editFormId === null) {
      if (data.isVisitDate && (this.visitDateFieldName.length !== 0)) {
        this.toastr.error(`Field ${this.visitDateFieldName} already marked as visit date`);
        return;
      } else if (data.isVisitDate && (this.visitDateFieldName.length === 0)) {
        this.visitDateFieldName = data.label;
      }
    }
    this.passVisitDateFieldName.emit(this.visitDateFieldName)

    if(this.validateData(data) && this.checkDuplicateAnnotation(data)){
      if(data.dataTypeId == componentTypeMap[17].id){
        this.removeButtonRowColumn(data);
      }
      if (this.editFormId) {
        this.addNewField();
      }else{
        if(!isNaN(this.indexData) && this.indexData !== null){
          this.tempForm.splice(this.indexData,1)
        }
        tempFormData.push(data);
        this.popupForm?.reset();
        localStorage.removeItem("element")
        this.ngbActiveModal?.close(true);
        this.modalService.dismissAll("Dismiss");
      }
      // this.popupForm?.reset();
      // localStorage.removeItem("element")
      // this.ngbActiveModal?.close(true);
      // this.modalService.dismissAll("Dismiss");
    }

  }

  validateMultiRowOptions(data:any){
    if(data.dataTypeId == componentTypeMap[16].id){
      if(data.columnData != undefined || data.columnData != null){
        for (let index = 0; index < data.columnData.length; index++) {
          if([6,7,8].includes(data.columnData[index].dataTypeId) && (data.columnData[index].options != undefined || data.columnData[index].options != null)){
            for (let j = 0; j < data.columnData[index].options.length; j++) {
              data.columnData[index].options[j].orderId = j
            }
          }
        }
      }
    }
  }

  operatorValidation() {
    var len = this.popupForm?.value.options.length;
    for (let i = 0; i < len; i++) {
      if (this.popupForm?.value.options[i].name == null || this.popupForm?.value.options[i].name == "") {
        this.toastr.warning(OPTION_NAME_ERROR_MSG);
        return;
      } else if (this.popupForm?.value.options[i].value == null || this.popupForm?.value.options[i].value == "") {
        this.toastr.warning(OPTION_VALUE_ERROR_MSG);
        return;
      } else if (this.popupForm?.value.options[i].code == null || this.popupForm?.value.options[i].code < 0) {
        this.toastr.warning(OPTION_CODE_ERROR_MSG);
        return;
      }else if (i == (len - 1) && this.popupForm?.value.options[i].name != "" && this.popupForm?.value.options[i].value != "" && this.popupForm?.value.options[i].code != "") {
        if(this.isEdit){
          
        }else{
          this.checkOperatorDataType(this.popupForm.value)
        }
      } else {
        // continue;
      }
    }
  }

  initForm(componentName: any) {
    this.popupForm = new FormGroup({
      type: new FormControl(componentName),
      dataTypeId: new FormControl(Components.indexOf(componentName) + 1),
      orderId:new FormControl()
    });
    switch (componentName) {
      case "TextView":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "placeholder", "unit", "required", "minValue", "maxValue", "isAlphabet"]);
        break;
      case "TextArea":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "placeholder", "required", "minValue", "maxValue", "isAlphabet"]);
        break;
      case "Number":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "placeholder", "defaultVal", "formulaId", "isFormula", "unit", "decimalPoints", "required", "minValue", "maxValue"]);
        break;
      case "Email":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "placeholder", "required", "minValue", "maxValue"]);
        break;
      case "Password":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "placeholder", "required", "minValue", "maxValue"]);
        break;
      case "CheckBox":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "defaultVal", "required", "options"]);
        break;
      case "RadioButton":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "defaultVal", "formatting", "required", "options"]);
        break;
      case "SelectionBox":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "defaultVal", "required", "options"]);
        break;
      case "Button":
        this.addControlsToForm(componentName, ["label", "value"]);
        break;
      case "Heading":
        this.addControlsToForm(componentName, ["label", "formatting"]);
        break;
      case "SubHeading":
        this.addControlsToForm(componentName, ["label", "formatting"]);
        break;
      case "Date":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note","required","minDate","maxDate", "formatting", "formulaId", "isFormula", "isVisitDate"]);
        break;
      case "Time":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note", "required", "formatting"]);
        break;
      case "Image":
        this.addControlsToForm(componentName, ["label", "note", "imagePath", "formatting"]);
        break;
      case "Label":
        this.addControlsToForm(componentName, ["label"]);
        break;
      case "HyperLink":
        this.addControlsToForm(componentName, ["label", "link"]);
        break;
      case "Multi-Row":
        this.addControlsToForm(componentName, ["label", "multiRowView", "columnData"]);
        break;
      case "Table":
        this.addControlsToForm(componentName, ["label","formatting","cellInfo"]);
        break;
      case "Date & Time":
        this.addControlsToForm(componentName, ["label", "annotatedName", "tag" , "note","required", "formatting"]);
        break;
      default:
        break;
    }
  }

  // Form Checkbox Options
  removeOption(index: any) {//remove existing checkbox option
    if ((<FormArray>this.popupForm?.get('options')).length > 1) {
      (<FormArray>this.popupForm?.get('options')).removeAt(index);
    } else {
      this.toastr.warning(MINIMUM_OPTION_ERROR_MSG);
    }
  }

  get optionFormGroups() {//For the HTML Rendering Part To show the options
    return this.popupForm?.get('options') as FormArray;
  }

  createOption(): FormGroup {//For Creating the Checkbox element Dynamically
    return new FormGroup({
      id: new FormControl(''),
      name: new FormControl(''),
      value: new FormControl(''),
      code: new FormControl(),
      orderId: new FormControl()
    });
  }

  addNewOption() {//add new option for checkbox
    (<FormArray>this.popupForm?.get('options')).push(this.createOption());
  }

  // For Fromatting
  onBoldCheckboxChange(event: any) {
    if (event.target.checked) {
      (this.popupForm?.get('formatting') as FormGroup).get('bold')?.setValue('bold')

    } else {
      (this.popupForm?.get('formatting') as FormGroup).get('bold')?.setValue('')
    }
  }

  onItalicCheckboxChange(event: any) {
    if (event.target.checked) {
      (this.popupForm?.get('formatting') as FormGroup).get('italic')?.setValue('italic')
    } else {
      (this.popupForm?.get('formatting') as FormGroup).get('italic')?.setValue('italic')
    }
  }

  onUnderlineCheckboxChange(event: any) {
    if (event.target.checked) {
      (this.popupForm?.get('formatting') as FormGroup).get('underline')?.setValue('underline')
    } else {
      (this.popupForm?.get('formatting') as FormGroup).get('underline')?.setValue('underline')
    }
  }

  onRadioAlignChange(event: any) {
    (this.popupForm?.get('formatting') as FormGroup).get('radioAlign')?.setValue(event.target.value)
  }

  imagePath: any;
  imgURL: any
  message: any;
  preview(files: any) {
    if (files[0].size / 4024 / 4024 > 2) {
      this.toastr.warning(IMAGE_SIZE_MSG);
      this.popupForm?.controls['imagePath'].reset();
      (<HTMLInputElement>document.getElementById("save")).disabled = true;
      return;
    }
    //  else {
    // (<HTMLInputElement>document.getElementById("save")).disabled = false;
    // }
    if (files.length === 0) {
      this.toastr.warning(IMAGE_NOT_FOUND_MSG);
      return;
    }
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result
      var data = this.imgURL.split(",")
      var data1 = data[1]
      this.popupForm?.controls['imagePath'].setValue(data1)
      localStorage.setItem("image",this.imgURL)
    }
  }

  setEditDataValues(itemId?: any){
    //this.popupForm?.patchValue(this.tempForm[this.indexData])
    if(this.indexData == null && isNaN(this.indexData)){
      this.popupForm?.reset()
    }

    if ( this.indexData != null && !isNaN(this.indexData) ) {
      
      if (this.editFormId != '' || (this.editFormId != 'null' && typeof(this.editFormId) != 'string')) {
        this.popupForm?.addControl("id", new FormControl());
      }
      let tempTimeFormating:any = {}
      if(this.tempForm.length > 0){
        if(this.tempForm[this.indexData].dataTypeId == componentTypeMap[12].id){
          let responseFormatting = this.tempForm[this.indexData].formatting
          if(responseFormatting != null){
            tempTimeFormating = new FormGroup({})
            for(let j in responseFormatting){
              if(responseFormatting[j] == "true"){
                tempTimeFormating.addControl(j,new FormControl(true))
              }else if(responseFormatting[j] == "false"){
                tempTimeFormating.addControl(j,new FormControl(false))
              }else{
                tempTimeFormating.addControl(j,new FormControl(responseFormatting[j]))
              }
            }
          }
        }
        if(this.tempForm[this.indexData].dataTypeId == 18){
          let row = Number(this.tempForm[this.indexData].formatting['row'])
          let column = Number(this.tempForm[this.indexData].formatting['column'])
          this.popupForm?.addControl("isRowColumnDataAdd",new FormControl(true))
          //Generating table on Update State
          if(row != null && column != null){
            this.getTableDataField.insert(0,new FormArray([])) //row
            this.getCellInfoData(0).insert(0,new FormArray([])) //column
            this.getCellInfoInnerArray(0,0).insert(0,new FormGroup({}))
            this.getCellInfoInnerArrayData(0,0,0).addControl("isButtonClicked",new FormControl(false))
            this.getCellInfoInnerArrayData(0,0,0).addControl("isHidden",new FormControl(false))
            this.getCellInfoInnerArrayData(0,0,0).addControl("isEdited",new FormControl(false))

            for (let i = 0; i < this.tempForm[this.indexData].cellInfo.length; i++) {
              this.getTableDataField.insert(i+1,new FormArray([]))
              this.getCellInfoData(i+1).insert(0,new FormArray([])) //column
              this.getCellInfoInnerArray(i+1,0).insert(0,new FormGroup({}))
              this.getCellInfoInnerArrayData(i+1,0,0).addControl("isButtonClicked",new FormControl(false))
              this.getCellInfoInnerArrayData(i+1,0,0).addControl("isHidden",new FormControl(false))
              this.getCellInfoInnerArrayData(i+1,0,0).addControl("isEdited",new FormControl(false))
              
              for (let j = 0; j < this.tempForm[this.indexData].cellInfo[i].length; j++) {
                if(i == 0){
                  this.getCellInfoData(0).insert(j+1,new FormArray([])) //column
                  this.getCellInfoInnerArray(0,j+1).insert(0,new FormGroup({}))

                  this.getCellInfoInnerArrayData(0,j+1,0).addControl("isButtonClicked",new FormControl(false))
                  this.getCellInfoInnerArrayData(0,j+1,0).addControl("isHidden",new FormControl(false))
                  this.getCellInfoInnerArrayData(0,j+1,0).addControl("isEdited",new FormControl(false))
                }
                this.getCellInfoData(i+1).insert(j+1, new FormArray([])) //column
                for (let k = 0; k < this.tempForm[this.indexData].cellInfo[i][j].length; k++) {
                  this.getCellInfoInnerArray(i+1,j+1).push(new FormGroup({})) //cell
                  if(this.tempForm[this.indexData].cellInfo[i][j][k] != undefined){
                    let keys = Object.keys(this.tempForm[this.indexData].cellInfo[i][j][k])
                    
                    keys.forEach((controlName:any) => {
                      if(controlName === "options"){
                        this.getCellInfoInnerArrayData(i+1,j+1,k).addControl(controlName,new FormArray([]))
                        for (let l = 0; l < this.tempForm[this.indexData].cellInfo[i][j][k][controlName].length; l++) {
                          (this.getCellInfoInnerArrayData(i+1,j+1,k).get("options") as FormArray).push(new FormGroup({}));
                          let keysData = Object.keys(this.tempForm[this.indexData].cellInfo[i][j][k][controlName][l]);
                          keysData.forEach((controlKey)=>{
                            ((this.getCellInfoInnerArrayData(i+1,j+1,k).get("options") as FormArray).get(l.toString()) as FormGroup).addControl(controlKey,new FormControl(this.tempForm[this.indexData].cellInfo[i][j][k][controlName][l][controlKey]))
                          });
                        }
                      }else{
                        this.getCellInfoInnerArrayData(i+1,j+1,k).addControl(controlName,new FormControl(this.tempForm[this.indexData].cellInfo[i][j][k][controlName]))
                      }
                    })
                  }
                  this.getCellInfoInnerArrayData(i+1,j+1,k).addControl("isButtonClicked",new FormControl(false))
                  this.getCellInfoInnerArrayData(i+1,j+1,k).addControl("isHidden",new FormControl(false))
                  this.getCellInfoInnerArrayData(i+1,j+1,k).addControl("isEdited",new FormControl(false))
                  if((i+1) === 1 || (j+1) === 1){
                    if(!this.getCellInfoInnerArrayData(i+1,j+1,k).contains("label")){
                      this.getCellInfoInnerArrayData(i+1,j+1,k).addControl("label",new FormControl())
                    }
                    this.getCellInfoInnerArrayData(i+1,j+1,k).addControl("isSaved",new FormControl(false))
                  }else{
                    if(this.getCellInfoInnerArrayData(i+1,j+1,k).contains("dataTypeId") != undefined && this.getCellInfoInnerArrayData(i+1,j+1,k).contains("dataTypeId")){
                      this.getCellInfoInnerArrayData(i+1,j+1,k).addControl("isSaved",new FormControl(true))
                    }else{
                      this.getCellInfoInnerArrayData(i+1,j+1,k).addControl("isSaved",new FormControl(false))
                    }
                  }
                }
              }
            }
          }
          this.popupForm?.get("id")?.setValue(this.tempForm[this.indexData].id)
          this.popupForm?.get("orderId")?.setValue(this.tempForm[this.indexData].orderId)
          this.popupForm?.get("type")?.setValue(this.tempForm[this.indexData].type)
          this.popupForm?.get("dataTypeId")?.setValue(this.tempForm[this.indexData].dataTypeId)
          this.popupForm?.get("label")?.setValue(this.tempForm[this.indexData].label)
          this.popupForm?.get("formatting")?.setValue(this.tempForm[this.indexData].formatting)
        }

        if(![17,18].includes(this.tempForm[this.indexData].dataTypeId)){
          if (this.tempForm[this.indexData].options && this.tempForm[this.indexData].options.length != undefined) {
            for (let i = 0; i < this.tempForm[this.indexData].options.length - 1; i++) {
              this.addNewOption();
            }
          }
        }
        
        if(this.tempForm[this.indexData].columnData && this.tempForm[this.indexData].columnData.length != undefined){
          
          for (let i = 0; i < this.tempForm[this.indexData].columnData.length; i++) {
            
            if(this.tempForm[this.indexData].columnData[i].dataTypeId != 12){
              
              delete this.tempForm[this.indexData].columnData[i].minDate
              delete this.tempForm[this.indexData].columnData[i].maxDate
  
            }
            if([6,7,8].includes(this.tempForm[this.indexData].columnData[i].dataTypeId)){
              
              delete this.tempForm[this.indexData].columnData[i].minValue
              delete this.tempForm[this.indexData].columnData[i].maxValue
              delete this.tempForm[this.indexData].columnData[i].minDate
              delete this.tempForm[this.indexData].columnData[i].maxDate
  
            }
            
            if(this.tempForm[this.indexData].columnData[i].dataTypeId != 3){
              delete this.tempForm[this.indexData].columnData[i].decimalPoints
              delete this.tempForm[this.indexData].columnData[i].unit
            }

            if(![1,2].includes(this.tempForm[this.indexData].columnData[i].dataTypeId)){
              delete this.tempForm[this.indexData].columnData[i].isAlphabet
            }

            if(![3,6,7,8].includes(this.tempForm[this.indexData].columnData[i].dataTypeId)){
              delete this.tempForm[this.indexData].columnData[i].defaultVal
            }

            if(this.tempForm[this.indexData].columnData[i].dataTypeId == componentTypeMap[14].id){
              delete this.tempForm[this.indexData].columnData[i].minValue
              delete this.tempForm[this.indexData].columnData[i].maxValue
              delete this.tempForm[this.indexData].columnData[i].minDate
              delete this.tempForm[this.indexData].columnData[i].maxDate
              delete this.tempForm[this.indexData].columnData[i].required
              delete this.tempForm[this.indexData].columnData[i].note
              delete this.tempForm[this.indexData].columnData[i].annotatedName
              delete this.tempForm[this.indexData].columnData[i].tag
              delete this.tempForm[this.indexData].columnData[i].options
              delete this.tempForm[this.indexData].columnData[i].formatting
              
            }
  
            const test:any = {};
            let testVar = this.tempForm[this.indexData].columnData[i]
            for (let x in testVar) {
              if(x == 'options'){
                test[x] = new FormArray([])
                for(let j in testVar[x]){
                  (test[x] as FormArray).controls.push(this.createColumnOption())
                }
              }else if(x == 'minDate'){
                test[x] = new FormControl()
                test[x].setValue(this.emptyJson)
                
              }else if(x == 'maxDate'){
                test[x] = new FormControl()
                test[x].setValue(this.emptyJson)
                
              }else if(x == 'formatting'){
                test[x] = new FormGroup({})
                for(let j in testVar[x]){
                  if(testVar[x][j] == "true"){
                    test[x].addControl(j,new FormControl(true))
                  }else if(testVar[x][j] == "false"){
                    test[x].addControl(j,new FormControl(false))
                  }else{
                    test[x].addControl(j,new FormControl(testVar[x][j]))
                  }
                }
                
              }else if(x == 'placeholder'){
                if(this.placeholderExist.includes(testVar.dataTypeId)){
                  test[x] = new FormControl('')
                }
              }else{
                test[x] = new FormControl(this.tempForm[this.indexData].columnData[i][x])
              }
            }
            
            (this.popupForm?.get('columnData') as FormArray).push(new FormGroup(test));
            this.tempForm[this.indexData].columnData[i].minDate = this.emptyJson
            this.tempForm[this.indexData].columnData[i].maxDate = this.emptyJson
          }
          
        }
        if(this.tempForm[this.indexData].dataTypeId != componentTypeMap[17].id){
          this.popupForm?.patchValue(this.tempForm[this.indexData]) //added this patch value here because in case of multiRow date and time checkbox all getting selected and if not added here then in option data only one data is coming.
        }
      }
      if(this.tempForm && this.tempForm[this.indexData].dataTypeId && this.tempForm[this.indexData].dataTypeId != componentTypeMap[17].id){
        if(this.tempForm[this.indexData].dataTypeId == componentTypeMap[12].id){
          // adding time formating for time component
          (this.popupForm as FormGroup).removeControl("formatting");
          (this.popupForm as FormGroup)?.addControl("formatting",tempTimeFormating)
        }
        //added this code because if formatting data is not present in the response then it will add the default value in radio component
        if(this.tempForm[this.indexData].dataTypeId == componentTypeMap[6].id){
          if(this.tempForm[this.indexData].formatting && (this.tempForm[this.indexData].formatting['radioAlignment'] == undefined || this.tempForm[this.indexData].formatting['radioAlignment'] == null || this.tempForm[this.indexData].formatting['radioAlignment'] == 'null')){
            let tempRadioFormating = new FormGroup({});
            tempRadioFormating.addControl("radioAlignment",new FormControl('Vertical'));
            (this.popupForm as FormGroup)?.addControl("formatting",tempRadioFormating)
          }
        }
        if(this.tempForm[this.indexData].dataTypeId == 12){
          if(typeof(this.tempForm[this.indexData].minDate) == 'object' && !loadashJson.isEqual(this.tempForm[this.indexData].minDate,{})){
            let minDate = {year:Number(this.tempForm[this.indexData].minDate['year']),month:Number(this.tempForm[this.indexData].minDate['month']),day:Number(this.tempForm[this.indexData].minDate['day'])}
            this.popupForm?.get("minDate")?.setValue(minDate);
          }
          if(typeof(this.tempForm[this.indexData].maxDate) == 'object' && !loadashJson.isEqual(this.tempForm[this.indexData].maxDate,{})){
            let maxDate = {year:Number(this.tempForm[this.indexData].maxDate['year']),month:Number(this.tempForm[this.indexData].maxDate['month']),day:Number(this.tempForm[this.indexData].maxDate['day'])}
            this.popupForm?.get("maxDate")?.setValue(maxDate);
          }
        }
        if(this.tempForm[this.indexData].dataTypeId == componentTypeMap[16].id){
          if(this.tempForm[this.indexData].columnData){
            for (let i = 0; i < this.tempForm[this.indexData].columnData.length; i++) {
              if(typeof(this.tempForm[this.indexData].columnData[i].minDate) == 'object' && !loadashJson.isEqual(this.tempForm[this.indexData].columnData[i].minDate,{})){
                let minDate = {year:Number(this.tempForm[this.indexData].columnData[i].minDate['year']),month:Number(this.tempForm[this.indexData].columnData[i].minDate['month']),day:Number(this.tempForm[this.indexData].columnData[i].minDate['day'])};
                ((this.popupForm?.get("columnData") as FormArray).get(i.toString()) as FormGroup).get("minDate")?.setValue(minDate);
              }
              if(typeof(this.tempForm[this.indexData].columnData[i].maxDate) == 'object' && !loadashJson.isEqual(this.tempForm[this.indexData].columnData[i].maxDate,{})){
                let maxDate = {year:Number(this.tempForm[this.indexData].columnData[i].maxDate['year']),month:Number(this.tempForm[this.indexData].columnData[i].maxDate['month']),day:Number(this.tempForm[this.indexData].columnData[i].maxDate['day'])};
                ((this.popupForm?.get("columnData") as FormArray).get(i.toString()) as FormGroup).get("maxDate")?.setValue(maxDate);
              }
              
            }
          }
        }
      }
      this.popupForm?.get("id")?.setValue(this.tempForm[this.indexData].id)
      
    }
  }

  addNewField() {
      if(this.tempForm.length == 0){
        this.popupForm?.controls['orderId'].setValue(1);
      }else{
        if(this.editFormId){
          this.popupForm?.controls['orderId'].setValue(this.tempForm.length + 1);
        }else{
          this.popupForm?.controls['orderId'].setValue(this.tempForm.length == 1 ? this.tempForm.length : this.tempForm.length + 1);
        }
      }
      if(this.popupForm?.get('options')){
        (<FormArray>this.popupForm?.get('options')).controls.filter((key:any,value:any)=>{
          key.controls['orderId'].setValue(value)
        })
      }
      let visit:any = localStorage.getItem("visitIds")
      let visitId = JSON.parse(visit)
      this.popupForm?.addControl("formId", new FormControl(this.editFormId));
      this.popupForm?.addControl("visitId", new FormControl(visitId[0]));
      this.loading = true
      if(this.popupForm?.get("dataTypeId")?.value == componentTypeMap[17].id){
        this.removeButtonRowColumn(this.popupForm?.value);
      }
      if(this.popupForm?.controls.columnData){
        this.setOrderForOptionsInMultirow(this.popupForm?.controls.columnData as FormArray);
      }
      this.fieldAPI.addFormField(this.popupForm?.value).subscribe(res => {
        if(res.status != 0){
          this.toastr.error(res.message)
          this.loading = false
        }else{
          this.toastr.success(res.message)

          localStorage.removeItem("element")
          localStorage.removeItem("copyElement")
          this.popupForm?.reset();
          this.modalService.dismissAll("Dismiss");
          if(!isNaN(this.indexData) && this.indexData !== null){
            this.tempForm.splice(this.indexData,1)
          }
          
          this.tempForm.push(res.responseObject);
        }
        this.loading = false
      },err=>{
        this.loading = false
      })
  }
  setOrderForOptionsInMultirow(columnData: FormArray<any>) {
    columnData.controls.forEach((col:any)=>{
      let column = (col as FormGroup);
      if( column.value.dataTypeId == 6 || column.value.dataTypeId == 7 || column.value.dataTypeId == 8 ){
        column.value.options.forEach((o:any,i:number) => {
          o.orderId = ++i;
        } )
      }
    });
  }

  updateData() {//for update data
    // this.tempForm.splice(this.indexData, 1, this.popupForm?.value);    

    if(this.popupForm?.get("dataTypeId")?.value == componentTypeMap[17].id){
      this.removeButtonRowColumn(this.popupForm?.value)
    }
    let isVisitDateData:boolean = this.popupForm?.get("isVisitDate")?.value
    if (this.editFormId == null) {
      if (isVisitDateData) {
        if (this.isCopyField) {
          if (this.visitDateFieldName.length !== 0) {
            this.toastr.error(`Field ${this.visitDateFieldName} already marked as visit date`);
            return;
          }
        } else {
          if ((this.visitDateFieldName === this.popupForm?.get("label")?.value) ||
            this.visitDateFieldName.length === 0) {
              this.visitDateFieldName = this.popupForm?.get("label")?.value
              this.isCopyField = false
          } else if (this.visitDateFieldName.length !== 0) {
            this.toastr.error(`Field ${this.visitDateFieldName} already marked as visit date`);
            return;
          }
        } 
      } else {
        if (!this.isCopyField && this.visitDateFieldName.length !== 0) {
          this.visitDateFieldName = ""
        }
      }
    }

    if (this.tempForm[this.indexData].id != null || this.tempForm[this.indexData].id != undefined) {
      // this.popupForm?.controls['orderId'].setValue(this.indexData);
      let visit:any = localStorage.getItem("visitIds")
      let visitId = JSON.parse(visit)
      this.popupForm?.addControl('formId', new FormControl(parseInt(this.editFormId)));
      if(visitId && visitId.length > 0){
        this.popupForm?.addControl('visitId', new FormControl(visitId[0]));
      }else{
        this.popupForm?.addControl('visitId', new FormControl(visitId));
      }
      if(this.popupForm?.get('options')){
        (<FormArray>this.popupForm?.get('options')).controls.filter((key:any,index:any)=>{
          key.controls['orderId'].setValue(index)
        })
      }
      if(this.validateData(this.popupForm?.value) && this.checkDuplicateAnnotation(this.popupForm?.value)){
        this.loading = true
        if(this.popupForm?.get("dataTypeId")?.value == componentTypeMap[17].id){
          this.removeButtonRowColumn(this.popupForm?.value)
        }
        this.fieldAPI.updateFormField(this.setOrderIdForMultirowOptionFields()).subscribe(res => {
          if(res.status != 0){
            this.toastr.error(res.message)
            this.loading = false
          }else{
              this.toastr.success(res.message)
              this.tempForm.splice(this.indexData, 1, res.responseObject);
              this.popupForm?.reset();
              this.modalService.dismissAll("Dismiss");
              this.loading = false
            }
        },err=>{
          this.loading = false
          // this.modalService.dismissAll("Dismiss");
  
        })
        // localStorage.removeItem("element")
        // localStorage.removeItem("copyElement")
        // this.popupForm?.reset();
        // this.modalService.dismissAll();
      }
    }else{

      if(this.validateData(this.popupForm?.value) && this.checkDuplicateAnnotation(this.popupForm?.value)){
        if (this.editFormId) {
          this.popupForm?.controls['orderId'].setValue(this.tempForm.length);
          this.addNewField()
        }else{
          if(!isNaN(this.indexData) && this.indexData !== null){
            this.tempForm.splice(this.indexData,1,this.popupForm?.value)
          }
          // tempFormData.push(this.popupForm?.value);
          localStorage.removeItem("element")
          localStorage.removeItem("copyElement")
          this.popupForm?.reset();
          this.modalService.dismissAll("Dismiss");
        }
      }
    }
    this.passVisitDateFieldName.emit(this.visitDateFieldName)
  }
  setOrderIdForMultirowOptionFields():any {
    if(Number(this.popupForm?.value?.dataTypeId) === 17 ){
      let form = JSON.parse(JSON.stringify(this.popupForm?.value));
      form?.columnData?.forEach((column:any) => {
        if([6,7,8].includes(Number(column.dataTypeId))){
          column.options?.forEach((option:any,index:number) => {
            option.orderId = index+1;
          })
        }
      });
      return form;
    }else{
      return this.popupForm?.value;
    };
  }

  updateDataPush() {//for update data
    if(this.indexData == 0){
      this.tempForm.splice(this.indexData, 1, this.popupForm?.value);
    }else{
      this.tempForm.splice(this.indexData, this.indexData, this.popupForm?.value);
    }
    this.popupForm?.reset();
    // this.modalService.dismissAll("Dismiss");
  }

  closePopup() {
    if(this.copyIndexData != null){
      this.tempForm.pop()
    }
    localStorage.removeItem("element")
    localStorage.removeItem("copyElement")
    this.ngbActiveModal?.close(false);
    this.modalService.dismissAll("Dismiss");
  }

  //Multi-Row Component
  createColumnData(): FormGroup {//For Creating the Checkbox element Dynamically
    return new FormGroup({
      fieldId:new FormControl(this.popupForm?.get('id')?.value),
      colHeading: new FormControl(''),
      type: new FormControl('')
    });
  }

  createColumnOption(): FormGroup {//For Creating the Checkbox element Dynamically
    return new FormGroup({
      id: new FormControl(''),
      name: new FormControl(''),
      value: new FormControl(''),
      code: new FormControl(),
      orderId: new FormControl()
    });
  }

  getColumnDataObject(index:any){
    return ((this.popupForm?.get("columnData") as FormArray).controls[index] as FormGroup)
  }

  optionFormGroupsInColumn(index:any) {//For the HTML Rendering Part To show the options
    return (((this.popupForm?.get("columnData") as FormArray).controls[index] as FormGroup).get("options") as FormArray).controls;
  }

  addNewOptionInColumn(index:any) {//add new option for checkbox
    (this.getColumnDataObject(index).get("options") as FormArray).push(this.createColumnOption());
  }

  removeOptionsInColumn(index:any,optionIndex:any) {//remove existing checkbox option
    if ((this.getColumnDataObject(index).get("options") as FormArray).length > 1) {
      (this.getColumnDataObject(index).get("options") as FormArray).removeAt(optionIndex);
    } else {
      this.toastr.warning(MINIMUM_OPTION_ERROR_MSG);
    }
  }

  move(formArray:any,shift:number, currentIndex:number) {
    let newIndex: number = currentIndex + shift;
    if(newIndex === -1) {
      newIndex = formArray.length - 1;
    } else if(newIndex == formArray.length) {
      newIndex = 0;
    }

    const currentGroup = formArray.at(currentIndex);
    formArray.removeAt(currentIndex);
    formArray.insert(newIndex, currentGroup)
  }

  drop(event: CdkDragDrop<DragDropData[]>) {
    moveItemInArray((<FormArray>this.popupForm?.get('columnData')).controls, event.previousIndex, event.currentIndex);
    (<FormArray>this.popupForm?.get('columnData')).controls.forEach((form, idx) => {
      form.get('orderId')?.setValue(idx+1);
    });
  }

  dropRadio(event: CdkDragDrop<any[]>,index?:any) {
    if (event.previousContainer !== event.container) {
      copyArrayItem(
        event.previousContainer.data,
        this.dataDone,
        event.previousIndex,
        event.currentIndex);
      // this.openComponent(this.data[event.previousIndex].heading);
      this.i++;
    } else {
      let r = confirm(DRAG_DROP_MESSAGE)
      if(r == true){
        let optionsData:any 
        if(this.popupForm?.get("dataTypeId")?.value == componentTypeMap[16].id){
          optionsData = (((this.popupForm.get("columnData") as FormArray).get(index.toString()) as FormGroup).get("options") as FormArray).controls
        }else{
          optionsData = (this.popupForm?.get('options') as FormArray).controls
        }
        
        let temp: any;
        moveItemInArray(optionsData, event.previousIndex, event.currentIndex);
        temp = this.dataDone[event.previousIndex];
        this.dataDone[event.previousIndex] = this.dataDone[event.currentIndex];
        this.dataDone[event.currentIndex] = temp;
        for (let i = 0; i < optionsData.length; i++) {
          optionsData[i]?.get('orderId')?.setValue(i+1);
        }
        
      }
    }
  }

  get getColumnData(){
    return this.popupForm?.get('columnData') as FormArray;
  }

  isExist(formGroup:any,controlName:any){
    return formGroup.contains(controlName)
  }

  removeColumnData(index: any) {//remove existing Columns
    if ((<FormArray>this.popupForm?.get('columnData')).length > 1) {
      (<FormArray>this.popupForm?.get('columnData')).removeAt(index);
      this.columnData.splice(index,1)
      this.heading.splice(index,1)
      this.columnComponentsElementArray.splice(index,1)
      this.columnComponentsElement.splice(index,1)
    } else {
      this.toastr.warning(COLUMN_REQUIRED_MSG);
    }
  }

  addNewColumnData() {//add new option for checkbox
    (<FormArray>this.popupForm?.get('columnData')).push(this.createColumnData());
  }

  onChangeColumnHeading(index:any){
    // this.heading[index] = this.popupForm?.controls['columnData'].value[index].colHeading
    return this.popupForm?.controls['columnData'].value[index].colHeading
  }

  addControlsToColumnForm(compName: any, list: any[],i:any) {
    let popUpColumn = ((this.popupForm?.get("columnData") as FormArray).controls[i] as FormGroup)
    let popColumnKeys = Object.keys(popUpColumn.controls)
    let defaultControls = ["fieldId","colHeading","type","annotatedName"]
    if(popColumnKeys){
      for (let index = 0; index < popColumnKeys.length; index++) {
        if(!defaultControls.includes(popColumnKeys[index])){
          popUpColumn.removeControl(popColumnKeys[index])
        }
      }
    }

    // popUpColumn = new FormGroup({
    //   fieldId:new FormControl(this.popupForm?.get('id')?.value),
    //   colHeading: new FormControl(popUpColumn.get('colHeading')?.value),
    //   type: new FormControl(popUpColumn.get('type')?.value)
    // });
    
    popUpColumn.addControl('dataTypeId',new FormControl(Components.indexOf(compName) + 1))
    popUpColumn.addControl('orderId',new FormControl(i))

    list.forEach((controlName:any) => {//formArray (for Options)
      if (controlName == "options") {
        popUpColumn.addControl(controlName, new FormArray([this.createOption()]));

      }else if (controlName == "placeholder") {
        popUpColumn.addControl(controlName,new FormControl(''));

      }else if (controlName == "note") {
        popUpColumn.addControl(controlName,new FormControl(''));

      }
      // else if (controlName == "isFormula") {
      //   popUpColumn.addControl(controlName,new FormControl());

      // }
      else if (controlName == "annotatedName") {
        popUpColumn.addControl(controlName,new FormControl());

      }else if (controlName == "minDate") {
        if(compName == "Date"){
          popUpColumn.addControl(controlName,new FormControl({}));
        }

      }else if (controlName == "maxDate") {
        if(compName == "Date"){
          popUpColumn.addControl(controlName,new FormControl({}));
        }
      }else if (controlName == "formatting") {
        if (compName == "Date") {
          popUpColumn.addControl("formatting",new FormGroup({
            dateFormat:new FormControl(this.studyFormat)  
          }));

        }
        else if (compName == "Time") {
          popUpColumn.addControl("formatting", new FormGroup({
            hour:new FormControl(true),
            minute:new FormControl(true),
            second:new FormControl(true),
            midDay:new FormControl(true),
            hourFormat:new FormControl('12 hrs')
          }));

        }
      }else if (controlName == "isAlphabet") {
        popUpColumn.addControl(controlName,new FormControl());
        
      }else {
        // formControls
        popUpColumn.addControl(controlName, new FormControl(''));//create new FormControl
      }
      if(this.popupForm != undefined){
        // this.popupForm.controls
        // (<FormArray>this.popupForm.get('columnData')).removeAt(i);
        // (<FormArray>this.popupForm.get('columnData')).push(popUpColumn);
        // this.popupForm.get('columnData')[i] = popUpColumn
      }
    });
  }

  onTypeSelected(event:any,index:any){
    let textVar = ((this.popupForm?.get('columnData') as FormArray).controls[index] as FormGroup).get('type')?.value;
    if(event.value != null){
      this.componentName[index] = event.value;
    }else{
      this.componentName[index] = event;
      
    }

    switch (textVar) {
      case "TextView":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "placeholder", "note", "required", "minValue", "maxValue", "isAlphabet"],index);
        break;
      case "Number":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "placeholder", "note", "decimalPoints", "unit", "defaultVal", "formulaId", "formulaId", "isFormula", "required", "minValue", "maxValue"],index);
        break;
      case "Email":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "placeholder", "note", "required", "minValue", "maxValue"],index);
        break;
      case "CheckBox":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "defaultVal", "note", "required", "options"],index);
        break;
      case "RadioButton":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "defaultVal", "note", "required", "options"],index);
        break;
      case "SelectionBox":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "defaultVal", "note", "required", "options"],index);
        break;
      case "Date":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "required", "note", "formulaId", "formulaId", "isFormula", "minDate", "maxDate", "formatting"],index);
        break;
      case "Time":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "note", "required", "formatting"],index);
        break;
      case "TextArea":
        this.addControlsToColumnForm(textVar, ["annotatedName","tag" , "note", "placeholder", "required", "minValue", "maxValue", "isAlphabet"],index);
        break;
      case  "Label":
        this.addControlsToColumnForm(textVar,[],index);
        break;
      default:
        break;
    }
  }

  //For Link Component
  displayLinkFormat(){
    let link = this.popupForm?.get('link')?.value;
    if (!/^https?:\/\//i.test(link)) {
      link = 'https://' + link;
    }
    this.popupForm?.get('link')?.setValue(link);
  }

  linkValidationCheck(){
    if(this.componentsElement.link){
      let link = this.popupForm?.get('link')?.value;
      let expression = "((https)://)[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)";
      let regex = new RegExp(expression);
      if(!link?.match(regex)){
        return false;
      }
      return true;
    }
    return true
  }

  //For Alphabet & AlphaNumeric
  onParameterValueChange(value: any){
    if (value && value === 'alphabet') {
      this.popupForm?.get('isAlphabet')?.setValue(0);
    }else{
      this.popupForm?.get('isAlphabet')?.setValue(1);
    }
  }

  //For Date & Time
  getDateYear() {
    for (let i = 1; i <= 31; i++) {
      this.dateData?.push(i);
    }
    for (let i = 1920; i <= 2050; i++) {
      this.yearData?.push(i);
    }
  }

  timeDataInit() {
    for (let i = 0; i < 12; i++) {
      if(i.toString().length < 2){
        this.hours12.push(('0' + i).slice(-2));
      }else{
        this.hours12.push(i.toString());
      }
    }
    
    for (let min = 0; min < 60; min++) {
      if(min.toString().length < 2){
        this.minutes.push(('0' + min).slice(-2));
      }else{
        this.minutes.push(min.toString());
      }
    }
    for (let hr = 0; hr < 24; hr++) {
      if(hr.toString().length < 2){
        this.hours24.push(('0' + hr).slice(-2));
      }else{
        this.hours24.push(hr.toString());
      }
    }
    for (let sec = 0; sec < 60; sec++) {
      if(sec.toString().length < 2){
        this.seconds.push(('0' + sec).slice(-2));
      }else{
        this.seconds.push(sec.toString());
      }
    }
  }

  getMinDate(item:any){
    if(loadashJson.isEqual(item.minDate,{}) || item.minDate == null){
      return this.minConstant as NgbDate
    }else{
      return new NgbDate(Number(item.minDate['year']),Number(item.minDate['month']),Number(item.minDate['day'])) 
    }
  }

  getMaxDate(item:any){
    if(loadashJson.isEqual({},item.maxDate) || item.maxDate == null){
      return this.maxConstant as NgbDate
    }else{
      return new NgbDate(Number(item.maxDate['year']),Number(item.maxDate['month']),Number(item.maxDate['day'])) 
       
    }
  }

  /*
    
    Table Component
  
  */
  //For validating the Row Column Data
  validateRowColumn(){
    if(this.getPopupFormFormattingKey("row") == null || this.getPopupFormFormattingKey("row") <= 0){
      this.toastr.warning("Enter Valid Rows")
      return false
    }else if(this.getPopupFormFormattingKey("column") == null || this.getPopupFormFormattingKey("column") <= 0){
      this.toastr.warning("Enter Valid Columns")
      return false
    }
    return true
  }

  //Get option data for Table Component
  getOptionTableDataField(rowIndex:any,columnIndex:any,cellIndex:any) {
    return (this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("options") as FormArray)
  }

  //Add option data for Table Component
  addOptionInTableDataField(rowIndex:any,columnIndex:any,cellIndex:any){
    (this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("options") as FormArray).push(this.createOption())
  }

  //Remove option data for Table Component
  removeOptionInTableDataField(rowIndex:any,columnIndex:any,cellIndex:any,cdkDropIndex:any){
    let options = this.getOptionTableDataField(rowIndex,columnIndex,cellIndex)
    if (options.length > 1) {
      options.removeAt(cdkDropIndex);
    } else {
      this.toastr.warning(MINIMUM_OPTION_ERROR_MSG);
    }
  }
  
  //Get CellInfo Data
  get getTableDataField(){
    return (this.popupForm?.get("cellInfo") as FormArray)
  }

  //Get CellInfo Data with Row
  getCellInfoData(rowIndex:any){
    return ((this.popupForm?.get("cellInfo") as FormArray).get(rowIndex.toString()) as FormArray)
  }

  //Get CellInfo Data with Row & Column
  getCellInfoInnerArray(rowIndex:any,columnIndex:any){
    return (((this.popupForm?.get("cellInfo") as FormArray).get(rowIndex.toString()) as FormArray).get(columnIndex.toString()) as FormArray)
  }

  //Get CellInfo Data with Row, Column & Cell
  getCellInfoInnerArrayData(rowIndex:any,columnIndex:any,cellIndex:any){
    return ((((this.popupForm?.get("cellInfo") as FormArray).get(rowIndex.toString()) as FormArray).get(columnIndex.toString()) as FormArray).get(cellIndex.toString()) as FormGroup)
  }

  //Get CellInfo Data with Row, Column & Cell
  getCellInfoInnerArrayDataValue(rowIndex:any,columnIndex:any,cellIndex:any,key:string){
    return ((((this.popupForm?.get("cellInfo") as FormArray).get(rowIndex.toString()) as FormArray).get(columnIndex.toString()) as FormArray).get(cellIndex.toString()) as FormGroup).get(key)
  }

  //Get Formatting data for the table component
  getPopupFormFormattingKey(key:string){
    return Number((this.popupForm?.get("formatting") as FormGroup).get(key)?.value)
  }

  //For Generating Table when click on Generate in table component 
  generateTable(){
    if(this.validateRowColumn()){
      if(this.popupForm?.get("isRowColumnDataAdd") == undefined){
        this.popupForm?.addControl("isRowColumnDataAdd",new FormControl());
      }
      this.popupForm?.get("isRowColumnDataAdd")?.setValue(true);
      (this.popupForm?.get("cellInfo") as FormArray).clear();
      (this.popupForm?.get("cellInfo") as FormArray).reset();
      let rows = Number((this.popupForm?.get("formatting") as FormGroup).get("row")?.value)
      let columns = Number((this.popupForm?.get("formatting") as FormGroup).get("column")?.value)
      rows = rows + 1
      columns = columns + 1
      
      if(rows != null && columns != null){
        for (let i = 0; i < rows; i++) {
          this.getTableDataField.push(new FormArray([]))
          for (let j = 0; j < columns; j++) {
            this.getCellInfoData(i).push(new FormArray([]))
            this.getCellInfoInnerArray(i,j).push(new FormGroup({}))
            for (let k = 0; k < this.getCellInfoInnerArray(i,j).length; k++) {
              if(i != 0 && j != 0){
                if(i === 1 || j === 1){
                  this.getCellInfoInnerArrayData(i,j,k).addControl("isHidden",new FormControl(false))
                  this.getCellInfoInnerArrayData(i,j,k).addControl("isEdited",new FormControl(false))
                  this.getCellInfoInnerArrayData(i,j,k).addControl("isSaved",new FormControl(false))
                  this.getCellInfoInnerArrayData(i,j,k).addControl("label",new FormControl())
                  this.getCellInfoInnerArrayData(i,j,k).addControl("dataTypeId",new FormControl(this.tableType[11].id))
                }else{
                  this.getCellInfoInnerArrayData(i,j,k).addControl("isButtonClicked",new FormControl(false))
                  this.getCellInfoInnerArrayData(i,j,k).addControl("isHidden",new FormControl(false))
                  this.getCellInfoInnerArrayData(i,j,k).addControl("isEdited",new FormControl(false))
                  this.getCellInfoInnerArrayData(i,j,k).addControl("isSaved",new FormControl(false))
                }
              }else{
                this.getCellInfoInnerArrayData(i,j,k).addControl("isHidden",new FormControl(false))
              }
            }
          }
        }
        // this.getCellInfoArrayData(0,1).addControl("colspan",new FormControl(2))
      }
    }
  }

  //For Reseting Table when click on Reset in table component
  resetTable(){
    (this.popupForm?.get("cellInfo") as FormArray).clear();
    (this.popupForm?.get("cellInfo") as FormArray).reset();
    this.popupForm?.get("isRowColumnDataAdd")?.setValue(false);
    (this.popupForm?.get("formatting") as FormGroup).get("row")?.reset();
    (this.popupForm?.get("formatting") as FormGroup).get("column")?.reset();
  }

  //When click on cell Plus button then this method will called
  generateCellForm(i:any,j:any,k:any){
    this.getCellInfoInnerArrayData(i,j,k).get("isButtonClicked")?.setValue(true)
    this.getCellInfoInnerArrayData(i,j,k).addControl("dataTypeId",new FormControl())
    let rows = Number((this.popupForm?.get("formatting") as FormGroup).get("row")?.value)
    let columns = Number((this.popupForm?.get("formatting") as FormGroup).get("column")?.value)
    if(rows != null && columns != null){
      for (let rowIndex = 0; rowIndex < this.getTableDataField.length; rowIndex++) {
        for (let columnIndex = 0; columnIndex < this.getCellInfoData(rowIndex).length; columnIndex++) {
          for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(rowIndex,columnIndex).length; cellIndex++) {
            if(rowIndex != i || columnIndex != j || cellIndex != k){
              this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("isHidden")?.setValue(true)
            }
          }
        }
      }
    }
  }

  //When click on close button when particular cell is on creation state then this method will called
  deGenerateCellForm(){
    for (let rowIndex = 0; rowIndex < this.getTableDataField.length; rowIndex++) {
      for (let columnIndex = 0; columnIndex < this.getCellInfoData(rowIndex).length; columnIndex++) {
        for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(rowIndex,columnIndex).length; cellIndex++) {
          this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("isButtonClicked")?.setValue(false)
          this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("isHidden")?.setValue(false)
        }
      }
    }
  }

  //Method to get fields by particular dataTypeId
  getCellDataTypeFields(dataTypeId:any,i:any,j:any){
    let componentName:any[] = []
    switch (dataTypeId){
      case this.tableType[0].id:
        componentName = ["annotatedName","tag","placeholder","note","unit","isAlphabet","required","minValue","maxValue"]
        break;
      case this.tableType[1].id:
        componentName = ["annotatedName","tag","placeholder","note","isAlphabet","required","minValue","maxValue"]
        break;
      case this.tableType[2].id:
        componentName = ["annotatedName","tag","placeholder","note","defaultVal","unit","decimalPoints", "formulaId","isFormula","required","minValue","maxValue"]
        break;
      case this.tableType[3].id:
        componentName = ["annotatedName","tag","placeholder","note","required","minValue","maxValue"]
        break;
      case this.tableType[4].id:
        componentName = ["annotatedName","tag","placeholder","note","required","minValue","maxValue"]
        break;
      case this.tableType[5].id:
        componentName = ["annotatedName","tag","placeholder","note","defaultVal","required","options"]
        break;
      case this.tableType[6].id:
        componentName = ["annotatedName","tag","placeholder","note","defaultVal","required","options"]
        break;
      case this.tableType[7].id:
        componentName = ["annotatedName","tag","placeholder","note","defaultVal","required","options"]
        break;
      case this.tableType[8].id:
        componentName = ["label","formatting"]
        break;
      case this.tableType[9].id:
        componentName = ["annotatedName","tag","note", "formulaId","isFormula","formatting","required","minDate","maxDate"]
        break;
      case this.tableType[10].id:
        componentName = ["annotatedName","tag","note","formatting","required"]
        break;
      case this.tableType[11].id:
        componentName = ["label"]
        break;
    }
    return componentName;
  }

  //When selecting "type" from accordion then this method is used
  getSelectedCellType(event:any,i:any,j:any,k:any){
    let typeNumber = Number(event.target.value)
    // this.getCellInfoInnerArrayData(i,j,k).reset()
    // Removing all FormControls
    let controlKey = Object.keys(this.getCellInfoInnerArrayData(i,j,k).value)
    
    controlKey.forEach(key => {
      
      if(this.getCellInfoInnerArrayData(i,j,k).value[key] === null || 
      loadashJson.isEqual(this.getCellInfoInnerArrayData(i,j,k).value[key],this.getCellInfoInnerArrayData(i,j,k).value.formatting) || 
      loadashJson.isEqual(this.getCellInfoInnerArrayData(i,j,k).value[key],this.getCellInfoInnerArrayData(i,j,k).value.options)){
        this.getCellInfoInnerArrayData(i,j,k).removeControl(key)
      }

    })

    // Adding Controller which are designed point-of-view
    let commonKeys:any = ["isButtonClicked","isHidden","dataTypeId","isSaved"]
    commonKeys.forEach((key:any) => {
      this.getCellInfoInnerArrayData(i,j,k).addControl(key,new FormControl())
    })

    // Setting Data In New FormControl
    this.getCellInfoInnerArrayData(i,j,k).get("isButtonClicked")?.setValue(true)
    this.getCellInfoInnerArrayData(i,j,k).get("isHidden")?.setValue(false)
    this.getCellInfoInnerArrayData(i,j,k).get("dataTypeId")?.setValue(typeNumber)
    this.getCellInfoInnerArrayData(i,j,k).addControl("isSaved",new FormControl(false))
    if(this.getCellInfoInnerArrayData(i,j,k).get("selectedType") == undefined){
      this.getCellInfoInnerArrayData(i,j,k).addControl("selectedType",new FormControl(event.target.options[event.target.selectedIndex].text))
    }else{
      this.getCellInfoInnerArrayData(i,j,k).get("selectedType")?.setValue(event.target.options[event.target.selectedIndex].text)
    }
    let dataTypeId = this.getCellInfoInnerArrayData(i,j,k).get("dataTypeId")?.value
    if(dataTypeId == this.tableType[9].id){
      this.getCellInfoInnerArrayData(i,j,k).addControl("isUnknownDate",new FormControl(false))
    }
    let componentName:any[] = this.getCellDataTypeFields(dataTypeId,i,j)
    componentName.forEach(controlName => {
      if(controlName === "options"){
        this.getCellInfoInnerArrayData(i,j,k).addControl(controlName,new FormArray([this.createOption()]))
      }else if(controlName == "formatting"){
        this.getCellInfoInnerArrayData(i,j,k).addControl(controlName,new FormGroup({}))
        if(dataTypeId == this.tableType[8].id){
          let formattingData:any[] = ["bold","italic","underline","alignment","color"];
          formattingData.forEach(format => {
            (this.getCellInfoInnerArrayData(i,j,k).get("formatting") as FormGroup).addControl(format,new FormControl())
          })
        }else if(dataTypeId == this.tableType[9].id){
          (this.getCellInfoInnerArrayData(i,j,k).get("formatting") as FormGroup).addControl("dateFormat",new FormControl(this.studyFormat))
        }else if(dataTypeId == this.tableType[10].id){
          let formattingData:any[] = ["hourFormat","hour","minute","second","midDay"];
          formattingData.forEach(format => {
            if(format == "hourFormat"){
              (this.getCellInfoInnerArrayData(i,j,k).get("formatting") as FormGroup).addControl(format,new FormControl('12 hrs'));
            }else if(!["hourFormat"].includes(format)){
              (this.getCellInfoInnerArrayData(i,j,k).get("formatting") as FormGroup).addControl(format,new FormControl(true));
            }else{
              (this.getCellInfoInnerArrayData(i,j,k).get("formatting") as FormGroup).addControl(format,new FormControl());

            }
          })
        }
      }else{
        this.getCellInfoInnerArrayData(i,j,k).addControl(controlName,new FormControl())
      }
    })
  }

  validateTableField(rowIndex:any,columnIndex:any,cellIndex:any){
    if(![11,15].includes(this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("dataTypeId")?.value)){
      if(this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("annotatedName")?.value == null || this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("annotatedName")?.value == ''){
        this.toastr.warning(FORM_ANNOTATEDNAME_ERROR_MSG)
        return false
      }
    }
    return true
  }

  // Save the paricular cell data 
  saveCell(i:any,j:any){
    // this.deGenerateCellForm()
    for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(i,j).length; cellIndex++) {
      if(this.validateTableField(i,j,cellIndex)){
        if(cellIndex == 0){
          this.deGenerateCellForm()
        }
        
        this.getCellInfoInnerArrayData(i,j,cellIndex).get("isSaved")?.setValue(true)
        let optionData = (this.getCellInfoInnerArrayData(i,j,cellIndex).get("options") as FormArray)
        if(optionData && optionData.length > 0){
          for (let index = 0; index < (this.getCellInfoInnerArrayData(i,j,cellIndex).get("options") as FormArray).length; index++) {
            (this.getOptionTableDataField(i,j,cellIndex).get(index.toString()) as FormGroup).get("orderId")?.setValue(index);
          }
        }
      }
    }
  }

  // Edit the paricular cell data
  editCell(i:any,j:any,k:any){
    // for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(i,j).length; cellIndex++) {
    //   this.addDataInCell(i,j,cellIndex)
    // }
    let dataTypeId:any
    for (let rowIndex = 0; rowIndex < this.getTableDataField.length; rowIndex++) {
      for (let columnIndex = 0; columnIndex < this.getCellInfoData(rowIndex).length; columnIndex++) {
        for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(rowIndex,columnIndex).length; cellIndex++) {
          
          dataTypeId = this.getCellInfoInnerArrayData(i,j,cellIndex).get("dataTypeId")?.value
          if(this.getCellInfoInnerArrayData(i,j,cellIndex).get("selectedType") == undefined){
            this.getCellInfoInnerArrayData(i,j,cellIndex).addControl("selectedType",new FormControl(this.tableType.find((data:any) => data.id == dataTypeId).name))
          }else{
            this.getCellInfoInnerArrayData(i,j,cellIndex).get("selectedType")?.setValue(this.tableType.find((data:any) => data.id == dataTypeId).name)
          }
          
          this.getCellInfoInnerArrayData(i,j,cellIndex).get("isSaved")?.setValue(false)
          this.getCellInfoInnerArrayData(i,j,cellIndex).get("isButtonClicked")?.setValue(true)
          
          if(this.getCellInfoInnerArrayData(i,j,cellIndex).get("isEdited") == undefined){
            this.getCellInfoInnerArrayData(i,j,cellIndex).addControl("isEdited",new FormControl(true))
          }else{
            this.getCellInfoInnerArrayData(i,j,cellIndex).get("isEdited")?.setValue(true)
          }
          
          if(rowIndex != i || columnIndex != j){
            this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("isHidden")?.setValue(true)
          }
        }
      }
    }
    this.oldCellData = loadashJson.cloneDeep(this.getCellInfoInnerArray(i,j))
    let controlNames:any[] = this.getCellDataTypeFields(dataTypeId,i,j)
    if(controlNames && controlNames.length > 0){
      controlNames.forEach((controlName:any)=>{
        this.getCellInfoInnerArrayData(i,j,0).addControl(controlName,new FormControl())
      })
    }
  }

  // Delete the paricular cell data
  deleteCell(rowIndex:any,columnIndex:any,k:any){
    // this.deGenerateCellForm()
    for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(rowIndex,columnIndex).length; cellIndex++) {
      if(this.getCellInfoInnerArray(rowIndex,columnIndex).length > 1){
        this.getCellInfoInnerArray(rowIndex,columnIndex).removeAt(cellIndex)
      }
      let dataTypeId = this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("dataTypeId")?.value
      let componentName:any[] = this.getCellDataTypeFields(dataTypeId,rowIndex,columnIndex)
      componentName.forEach(key => {
        this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).removeControl(key)
      })
      this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).removeControl("dataTypeId")
      this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("isSaved")?.setValue(false)
      this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("isButtonClicked")?.setValue(false)
    }
    
  }

  // Cancel the add/updating cell data
  cancelCell(i:any,j:any){
    for (let rowIndex = 0; rowIndex < this.getTableDataField.length; rowIndex++) {
      for (let columnIndex = 0; columnIndex < this.getCellInfoData(rowIndex).length; columnIndex++) {
        for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(rowIndex,columnIndex).length; cellIndex++) {
          this.getCellInfoInnerArray(i,j).removeAt(cellIndex) 
        }
      }
    }
    for (let oldData = 0; oldData < this.oldCellData.length; oldData++) {
      this.getCellInfoInnerArray(i,j).push(this.oldCellData.get(oldData.toString()))
      this.getCellInfoInnerArrayData(i,j,oldData).get("isSaved")?.setValue(true)
      this.getCellInfoInnerArrayData(i,j,oldData).get("isButtonClicked")?.setValue(false)
      this.getCellInfoInnerArrayData(i,j,oldData).get("isEdited")?.setValue(false)
    }
    this.deGenerateCellForm()
  }

  //For merging cell from left 
  // mergeFromLeftCell(i:any,j:any,k:any){
  //   let row:any = i
  //   let column:any = j
  //   for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(i,j).length; cellIndex++) {
  //     this.getCellInfoInnerArrayData(i,j,cellIndex).addControl("colspan",new FormControl())
  //     if(this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.value == null){
  //       this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.setValue(2)
  //     }else{
  //       this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.setValue(this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.value + 1)
  //     }
  //     //For Hide the column level copy for that particular row
  //     this.getCellInfoInnerArrayData(0,j,cellIndex).addControl("isColSpan",new FormControl(true))
  //     this.getCellInfoInnerArrayData(i,0,cellIndex).addControl("isRowSpan",new FormControl(true))
  //   }
  //   column = j - 1
  //   this.getCellInfoData(row).removeAt(column)
  //   //For Hide the column level copy for that particular row
  //   this.getCellInfoInnerArrayData(0,column,0).addControl("isColSpan",new FormControl(true))
  //   this.getCellInfoInnerArrayData(row,0,0).addControl("isRowSpan",new FormControl(true))
  //   console.log("POPUP FORM DATA :: ",this.popupForm);
  // }

  //For merging cell from right
  mergeFromRightCell(i:any,j:any,k:any){
    let row:any = i
    let column:any = j
    
    for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(i,j).length; cellIndex++) {
      this.getCellInfoInnerArrayData(i,j,cellIndex).removeControl("id")
      this.getCellInfoInnerArrayData(i,j,cellIndex).addControl("colspan",new FormControl())
      if(this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.value == null){
        this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.setValue(2)
      }else{
        this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.setValue(this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.value + 1)
      }
      //For Hide the column level copy for that particular row
      this.getCellInfoInnerArrayData(0,j,cellIndex).addControl("isColSpan",new FormControl(true))
      this.getCellInfoInnerArrayData(i,0,cellIndex).addControl("isRowSpan",new FormControl(true))
    }
    
    column = j + 1
    this.getCellInfoData(row).removeAt(column)

    //For Hide the column level copy for that particular row
    let colSpan = this.getCellInfoInnerArrayData(i,j,0).get("colspan")?.value
    let colSpanCalculation = (j + Number(colSpan != null ? colSpan : 0)-1)
    this.getCellInfoInnerArrayData(0,colSpanCalculation,0).addControl("isColSpan",new FormControl(true))
    // this.getCellInfoInnerArrayData(i,0,0).addControl("isRowSpan",new FormControl(true))
  }

  //For merging cell from bottom
  mergeFromBottomCell(i:any,j:any,k:any){
    let row:any = i
    let column:any = j
    for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(i,j).length; cellIndex++) {
      this.getCellInfoInnerArrayData(i,j,cellIndex).removeControl("id")
      this.getCellInfoInnerArrayData(i,j,cellIndex).addControl("rowspan",new FormControl())
      if(this.getCellInfoInnerArrayData(i,j,cellIndex).get("rowspan")?.value == null){
        this.getCellInfoInnerArrayData(i,j,cellIndex).get("rowspan")?.setValue(2)
      }else{
        this.getCellInfoInnerArrayData(i,j,cellIndex).get("rowspan")?.setValue(this.getCellInfoInnerArrayData(i,j,cellIndex).get("rowspan")?.value + 1)
      }
      //For Hide the row level copy for that particular row
      this.getCellInfoInnerArrayData(i,0,cellIndex).addControl("isRowSpan",new FormControl(true))
      this.getCellInfoInnerArrayData(0,j,cellIndex).addControl("isColSpan",new FormControl(true))
    }
    if(row != 0){
      row = i + (Number(this.getCellInfoInnerArrayData(i,j,k).get("rowspan")?.value)-1)
    }else{
      row = i + 1
    }
    this.getCellInfoData(row).removeAt(column)
    
    //For Hide the row level copy for that particular row
    this.getCellInfoInnerArrayData(row,0,0).addControl("isRowSpan",new FormControl(true))
    this.getCellInfoInnerArrayData(0,j,0).addControl("isColSpan",new FormControl(true))
  }

  //For split the merging column
  splitColumn(i:any,j:any,k:any){
    //getting the colSpan Data
    let colSpanKey = this.getCellInfoInnerArrayData(i,j,k).get("colspan")
    if(colSpanKey?.value != undefined){
      //Push the new formGroup on particular row
      this.getCellInfoData(i).push(new FormArray([]))
      //Generate the data for that particular cell
      this.getCellInfoInnerArray(i,(this.getCellInfoData(i).length-1)).push(new FormGroup({
        isButtonClicked:new FormControl(false),
        isHidden:new FormControl(false),
        isEdited:new FormControl(false),
        isSaved:new FormControl(false),
        dataTypeId:new FormControl()
      }))

      //For Show the column level copy for that particular column
      let colSpan = this.getCellInfoInnerArrayData(i,j,0).get("colspan")?.value
      let colSpanCalculation = (j + Number(colSpan != null ? colSpan : 0)-1)
      this.getCellInfoInnerArrayData(0,colSpanCalculation,0).removeControl("isColSpan")

      //colSpan value decrement if the split row is done
      for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(i,j).length; cellIndex++) {
        this.getCellInfoInnerArrayData(i,j,cellIndex).removeControl("id")
        let colValue = this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.value
        if(colValue == 2){
          this.getCellInfoInnerArrayData(i,j,cellIndex).removeControl("colspan")
          //For Show the column level copy for that particular column
          this.getCellInfoInnerArrayData(0,j,0).removeControl("isColSpan")
          this.getCellInfoInnerArrayData(i,0,0).removeControl("isRowSpan")

        }else if(colValue > 2){
          this.getCellInfoInnerArrayData(i,j,cellIndex).get("colspan")?.setValue(colValue-1)
        }
      }

    }else{
      this.toastr.warning("Cannot split column")
    }
  }
  
  //For split the merging rows
  splitRow(i:any,j:any,k:any){
    //Fetching rowSpan data
    let rowSpanKey = this.getCellInfoInnerArrayData(i,j,k).get("rowspan")
    if(rowSpanKey?.value != undefined){
      //inserting data on a particular columnIndex
      //inserting on particular rowSpan Data rowIndex
      this.getCellInfoData((i + Number(rowSpanKey.value)) - 1 ).insert(j,new FormArray([]))
      this.getCellInfoInnerArray((i + Number(rowSpanKey.value)) - 1 ,j).insert(j,new FormGroup({
        isButtonClicked:new FormControl(false),
        isHidden:new FormControl(false),
        isEdited:new FormControl(false),
        isSaved:new FormControl(false),
        dataTypeId:new FormControl()
      }))

      //For Show the row level copy for that particular row
      this.getCellInfoInnerArrayData((i + Number(rowSpanKey.value)) - 1 ,0,0).removeControl("isRowSpan")
      
      for (let cellIndex = 0; cellIndex < this.getCellInfoInnerArray(i,j).length; cellIndex++) {
        this.getCellInfoInnerArrayData(i,j,cellIndex).removeControl("id")
        let colValue = this.getCellInfoInnerArrayData(i,j,cellIndex).get("rowspan")?.value
        if(colValue == 2){
          this.getCellInfoInnerArrayData(i,j,cellIndex).removeControl("rowspan")
          //For Show the row level copy for that particular row
          this.getCellInfoInnerArrayData(i,0,0).removeControl("isRowSpan")
          this.getCellInfoInnerArrayData(0,j,0).removeControl("isColSpan")
        }else if(colValue > 2){
          this.getCellInfoInnerArrayData(i,j,cellIndex).get("rowspan")?.setValue(colValue-1)
        }
      }
      
    }else{
      this.toastr.warning("Cannot split row")
    }
  }

  // Add another accordion on same cell
  addDataInCell(i:any,j:any){
    this.getCellInfoInnerArray(i,j).push(new FormGroup({
      isButtonClicked:new FormControl(true),
      isHidden:new FormControl(false),
      isEdited:new FormControl(false),
      dataTypeId:new FormControl()
    }))
  }

  // remove all accordion on same cell
  removeDataFromCell(rowIndex:any,columnIndex:any){
    if(this.getCellInfoInnerArray(rowIndex,columnIndex) != null){
      for (let i = 0; i < this.getCellInfoInnerArray(rowIndex,columnIndex).length; i++) {
        this.getCellInfoInnerArray(rowIndex,columnIndex).removeAt(i)
      }
    }
    
    // this.addDataInCell(rowIndex,columnIndex)
  }

  // remove existing accordion on same cell
  removeDataInCell(i:any,j:any,k:any){
    if(this.getCellInfoInnerArray(i,j).length > 1){
      this.getCellInfoInnerArray(i,j).removeAt(k)
    }else{
      this.toastr.warning("One field design is requied")
    }
  }

  checkDateIsUnkown(event:any,rowIndex:any,columnIndex:any,cellIndex:any){
    this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("isUnknownDate")?.setValue(event.target.checked)
  }

  onTableBoldCheckboxChange(event: any,rowIndex:any,columnIndex:any,cellIndex:any) {
    
    if (event.target.checked) {
      (this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("formatting") as FormControl).get('bold')?.setValue('bold')
    } else {
      (this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("formatting") as FormControl).get('bold')?.setValue('')
    }
  }

  onTableItalicCheckboxChange(event: any,rowIndex:any,columnIndex:any,cellIndex:any) {
    if (event.target.checked) {
      (this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("formatting") as FormControl).get('italic')?.setValue('italic')
    } else {
      (this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("formatting") as FormControl).get('italic')?.setValue('italic')
    }
  }

  onTableUnderlineCheckboxChange(event: any,rowIndex:any,columnIndex:any,cellIndex:any) {
    if (event.target.checked) {
      (this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("formatting") as FormControl).get('underline')?.setValue('underline')
    } else {
      (this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("formatting") as FormControl).get('underline')?.setValue('underline')
    }
  }

  //Row Column Functionalities
  //Add new row to table component
  addRowColumn(rowIndex:any,columnIndex:any,isRowOrColumn:string){
    let rows = Number((this.popupForm?.get("formatting") as FormGroup).get("row")?.value)
    let columns = Number((this.popupForm?.get("formatting") as FormGroup).get("column")?.value)
    rows = rows + 1
    columns = columns + 1
    if(isRowOrColumn == 'row' && columns != null){
      rowIndex = rowIndex + 1
      this.getTableDataField.insert(rowIndex,new FormArray([]))
      for (let j = 0; j < columns; j++) {
        this.getCellInfoData(rowIndex).push(new FormArray([]))
        this.getCellInfoInnerArray(rowIndex,j).push(new FormGroup({}))
        for (let k = 0; k < this.getCellInfoInnerArray(rowIndex,j).length; k++) {
          if(j != 0){
            if(rowIndex === 1 || j === 1){
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("isHidden",new FormControl(false))
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("isEdited",new FormControl(false))
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("isSaved",new FormControl(false))
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("label",new FormControl())
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("dataTypeId",new FormControl(this.tableType[11].id))
            }else{
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("isButtonClicked",new FormControl(false))
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("isHidden",new FormControl(false))
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("isEdited",new FormControl(false))
              this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("isSaved",new FormControl(false))
            }
          }else{
            this.getCellInfoInnerArrayData(rowIndex,j,k).addControl("isHidden",new FormControl(false))
          }
        }
      }
      (this.popupForm?.get("formatting") as FormGroup).get("row")?.setValue(rows)
    }else if(isRowOrColumn == 'column' && rows != null){
      columnIndex = columnIndex+1
      for (let i = 0; i < rows; i++) {
        this.getCellInfoData(i).insert(columnIndex,new FormArray([]))
        this.getCellInfoInnerArray(i,columnIndex).push(new FormGroup({}))
        for (let k = 0; k < this.getCellInfoInnerArray(i,columnIndex).length; k++) {
          if(i != 0){
            if(i === 1 || columnIndex === 1){
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("isHidden",new FormControl(false))
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("isEdited",new FormControl(false))
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("isSaved",new FormControl(false))
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("label",new FormControl())
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("dataTypeId",new FormControl(this.tableType[11].id))
            }else{
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("isButtonClicked",new FormControl(false))
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("isHidden",new FormControl(false))
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("isEdited",new FormControl(false))
              this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("isSaved",new FormControl(false))
            }
          }else{
            this.getCellInfoInnerArrayData(i,columnIndex,k).addControl("isHidden",new FormControl(false))
          }
        }
      }
      (this.popupForm?.get("formatting") as FormGroup).get("column")?.setValue(columns)
    }

  }
  
  //Delete new row to table component
  deleteRowColumn(rowIndex:any,columnIndex:any,isRowOrColumn:string){
    let rows = Number((this.popupForm?.get("formatting") as FormGroup).get("row")?.value)
    let columns = Number((this.popupForm?.get("formatting") as FormGroup).get("column")?.value)
    let rowsAdd = rows + 1
    let columnAdd = columns + 1
    if(isRowOrColumn == 'row' && columns != null){
      this.getTableDataField.removeAt(rowIndex);
      (this.popupForm?.get("formatting") as FormGroup).get("row")?.setValue(rows - 1)
    }else if(isRowOrColumn == 'column' && rows != null){
      for (let i = 0; i < rowsAdd; i++) {
        this.getCellInfoData(i).removeAt(columnIndex)
      }
      (this.popupForm?.get("formatting") as FormGroup).get("column")?.setValue(columns - 1)
    }

  }

  //Copy existing row to table component
  copyRowColumn(rowIndex:any,columnIndex:any,isRowOrColumn:string){
    let rows = Number((this.popupForm?.get("formatting") as FormGroup).get("row")?.value)
    let columns = Number((this.popupForm?.get("formatting") as FormGroup).get("column")?.value)
    rows = rows + 1
    columns = columns + 1
    if(isRowOrColumn == 'row' && columns != null){
      let rowIndexAdd = rowIndex + 1
      this.getTableDataField.insert(rowIndexAdd,new FormArray([]))
      // let oldData = this.getCellInfoData(rowIndex)
      for (let j = 0; j < this.getCellInfoData(rowIndex).length; j++) {
        this.getCellInfoData(rowIndexAdd).push(loadashJson.cloneDeep(this.getCellInfoData(rowIndex).get(j.toString())))
        for (let k = 0; k < this.getCellInfoInnerArray(rowIndexAdd,j).length; k++) {
          this.getCellInfoInnerArrayData(rowIndexAdd,j,k).removeControl("id")
          if(this.getCellInfoInnerArrayData(rowIndexAdd,j,k).get("options")){
            for (let l = 0; l < (<FormArray>this.getCellInfoInnerArrayData(rowIndexAdd,j,k).get("options"))?.length; l++) {
              (<FormGroup>(<FormArray>this.getCellInfoInnerArrayData(rowIndexAdd,j,k).get("options")).get(l.toString())).removeControl("id");
              (<FormGroup>(<FormArray>this.getCellInfoInnerArrayData(rowIndexAdd,j,k).get("options")).get(l.toString())).removeControl("fieldId");
            }
          }
        }
      }
      (this.popupForm?.get("formatting") as FormGroup).get("row")?.setValue(Number(rows))
    }else if(isRowOrColumn == 'column' && rows != null){
      let columnIndexAdd = columnIndex+1
      for (let i = 0; i < rows ; i++) {
        this.getCellInfoData(i).insert(columnIndexAdd,new FormArray([]))
        let oldData = this.getCellInfoInnerArray(i,columnIndex)
        for (let j = 0; j < oldData.length; j++) {
          this.getCellInfoInnerArray(i,columnIndexAdd).push(loadashJson.cloneDeep(oldData.get(j.toString())))
          for (let k = 0; k < this.getCellInfoInnerArray(i,columnIndexAdd).length; k++) {
            this.getCellInfoInnerArrayData(i,columnIndexAdd,k).removeControl("id")
            if(this.getCellInfoInnerArrayData(i,columnIndexAdd,k).get("options")){
              for (let l = 0; l < (<FormArray>this.getCellInfoInnerArrayData(i,columnIndexAdd,k).get("options"))?.length; l++) {
                (<FormGroup>(<FormArray>this.getCellInfoInnerArrayData(i,columnIndexAdd,k).get("options")).get(l.toString())).removeControl("id");
                (<FormGroup>(<FormArray>this.getCellInfoInnerArrayData(i,columnIndexAdd,k).get("options")).get(l.toString())).removeControl("fieldId");
              }
            }
          }
          
        }
      }
      (this.popupForm?.get("formatting") as FormGroup).get("column")?.setValue(columns)
    }
  }

  //Drag & drop Row/Column & Cell
  dropRow(event:CdkDragDrop<any>){
    if(event.currentIndex != 0 && event.previousIndex != 0){
      moveItemInArray(this.getTableDataField.controls, event.currentIndex, event.previousIndex);
    }
  }

  //Remove 1st Row & Column
  removeButtonRowColumn(data:any){
    if(data.dataTypeId != null && data.dataTypeId == componentTypeMap[17].id){
      if(data.cellInfo != null && data.cellInfo.length > 0){
        (data.cellInfo as Array<any>).splice(0,1)
        for ( let rowIndex = 0; rowIndex < data.cellInfo.length; rowIndex++ ) {
          (data.cellInfo[rowIndex] as Array<any>).splice(0,1)
          for( let columnIndex = 0; columnIndex < data.cellInfo[rowIndex].length; columnIndex++ ){
            for( let cellIndex = 0; cellIndex < data.cellInfo[rowIndex][columnIndex].length; cellIndex++ ){
              delete data.cellInfo[rowIndex][columnIndex][cellIndex].isButtonClicked
              delete data.cellInfo[rowIndex][columnIndex][cellIndex].isEdited
              delete data.cellInfo[rowIndex][columnIndex][cellIndex].isHidden
              delete data.cellInfo[rowIndex][columnIndex][cellIndex].isSaved
              delete data.cellInfo[rowIndex][columnIndex][cellIndex].selectedType
            }
          }
        }
      }
    }
  }

  dropTableComponentRadio(event: CdkDragDrop<any[]>,rowIndex:any,columnIndex:any,cellIndex:any) {
    let r = confirm(MOVE_QUESTION)
    if(r == true){
      let optionsData = this.getOptionTableDataField(rowIndex,columnIndex,cellIndex).controls 
      let temp: any;
      moveItemInArray(optionsData, event.previousIndex, event.currentIndex);
      temp = this.dataDone[event.previousIndex];
      this.dataDone[event.previousIndex] = this.dataDone[event.currentIndex];
      this.dataDone[event.currentIndex] = temp;
      for (let i = 0; i < optionsData.length; i++) {
        optionsData[i]?.get('orderId')?.setValue(i);
      }
    }
  }

  //Save Table Component Label data
  saveLabel(rowIndex:any,columnIndex:any,cellArrayIndex:any){
    this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).get("isSaved")?.setValue(true)
    this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).get("isEdited")?.setValue(false)
  }
  
  updateLabel(rowIndex:any,columnIndex:any,cellArrayIndex:any){
    this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).get("isSaved")?.setValue(false)
    this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).get("isEdited")?.setValue(true)
  }

  closeLabel(rowIndex:any,columnIndex:any,cellArrayIndex:any){
    this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).get("isSaved")?.setValue(true)
    this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellArrayIndex).get("isEdited")?.setValue(false)

  }

  //Multi-Row Column Copy

  // get getColumnDataFieldLength(){
  //   return (this.popupForm?.get("columnData") as FormArray)
  // }

  copyMultiRowColumn(index:any){
    let indexData = cloneArray(((this.popupForm?.get("columnData") as FormArray).get(index.toString()) as FormGroup).value);
    if(indexData){
      delete indexData.id
      if(indexData.options){
        for (let i = 0; i < indexData.options.length; i++) {
          delete indexData.options[i].id
          delete indexData.options[i].fieldId
        }
      }
    }
    let length = (this.popupForm?.get("columnData") as FormArray).length;
    (this.popupForm?.get("columnData") as FormArray).insert(length,new FormGroup({}));
    Object.keys(indexData).find((data:any) => {
      if(data == "options"){
        ((this.popupForm?.get("columnData") as FormArray)
        .get(length.toString()) as FormGroup).addControl(data,new FormArray([]))
        if(indexData.options){
          for (let i = 0; i < indexData.options.length; i++) {
            (((this.popupForm?.get("columnData") as FormArray)
            .get(length.toString()) as FormGroup)
            .get(data) as FormArray).push(
              new FormGroup({
                name: new FormControl(indexData.options[i].name),
                value: new FormControl(indexData.options[i].value),
                code: new FormControl(indexData.options[i].code),
                orderId: new FormControl(i)
              })
            )
          }
        }
      }else if(data == "formatting"){
        if (indexData.dataTypeId == componentTypeMap[11].id) {
          ((this.popupForm?.get("columnData") as FormArray).get(length.toString()) as FormGroup).addControl(data, new FormGroup({
            dateFormat: new FormControl(indexData.formatting.dateFormat),
          }));
        }else if (indexData.dataTypeId == componentTypeMap[12].id) {
          ((this.popupForm?.get("columnData") as FormArray).get(length.toString()) as FormGroup).addControl(data, new FormGroup({
            hour: new FormControl(indexData.formatting.hour),
            minute: new FormControl(indexData.formatting.minute),
            second: new FormControl(indexData.formatting.second),
            midDay: new FormControl(indexData.formatting.midDay),
            hourFormat: new FormControl(indexData.formatting.hourFormat)
          }));
        }
      }else if(data == "annotatedName"){
        ((this.popupForm?.get("columnData") as FormArray).get(length.toString()) as FormGroup).addControl(data,new FormControl())
      }else if(data == "colHeading"){
        ((this.popupForm?.get("columnData") as FormArray).get(length.toString()) as FormGroup).addControl(data,new FormControl(indexData[data]+"-Copy"))
      }else if(data == "orderId"){
        ((this.popupForm?.get("columnData") as FormArray).get(length.toString()) as FormGroup).addControl(data,new FormControl(length))
      }else{
        ((this.popupForm?.get("columnData") as FormArray).get(length.toString()) as FormGroup).addControl(data,new FormControl(indexData[data]))
      }
    })
    // let str = "Accordion-"+length
    // let docData = <HTMLElement>document.getElementById(index.toString())
    // console.dir(docData)
    // docData.autofocus = true
    // this.accordion?.toggle(length.toString())
  }

  // Duplicate Annotation Warning
  checkDuplicateAnnotation(data:any){
    for (let i = 0; i < tempFormData.length; i++) {
      if (tempFormData[i].annotatedName != undefined && data.annotatedName != undefined && tempFormData[i].annotatedName.toLowerCase() == data.annotatedName.toLowerCase()) {
        if(this.isEdit && this.indexData != i){
          this.toastr.warning("Duplicate annotation name for " + tempFormData[i].label + " and " + data.label);
          return false;
        }else if(this.isEdit == false){
          this.toastr.warning("Duplicate annotation name for " + tempFormData[i].label + " and " + data.label);
          return false;
        }
      }
    }
    return true
  }

  //Reset Min-Max Date
  resetDate(isMinDate:boolean,field:any,multiData?:any,rowIndex?:any,columnIndex?:any,cellIndex?:any){
    if(isMinDate){
      if(field === 'simpleField'){
        this.popupForm?.get("minDate")?.reset()
      }else if(field === 'multiRowTableField'){
        multiData.get("minDate").reset()
      }else if(field === 'tableComponent'){
        this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("minDate")?.reset()
      }
    }else{
      if(field === 'simpleField'){
        this.popupForm?.get("maxDate")?.reset()
      }else if(field === 'multiRowTableField'){
        multiData.get("maxDate").reset()
      }else if(field === 'tableComponent'){
        this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get("maxDate")?.reset()
      }
    }
  }

  resetData(key:any,field:any,multiData?:any,rowIndex?:any,columnIndex?:any,cellIndex?:any){
    if(field === 'simpleField'){
      this.popupForm?.get(key)?.reset()
    }else if(field === 'multiRowTableField'){
      multiData.get(key).reset()
    }else if(field === 'tableComponent'){
      this.getCellInfoInnerArrayData(rowIndex,columnIndex,cellIndex).get(key)?.reset()
    }
  }
}